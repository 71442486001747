<script lang="ts" setup>
import { DocumentState } from "~/types/enums/DocumentState";

const { state } = defineProps({
  state: {
    type: String,
    required: true,
  },
  filled: {
    type: Boolean,
    default: true,
  },
});

const stateLabel = computed(() => {
  switch (state) {
    case DocumentState.pending:
      return "Pending";
    case DocumentState.uploading:
      return "Uploading";
    case DocumentState.converting:
      return "Converting";
    case DocumentState.conversionFailed:
      return "Error";
    case DocumentState.studentReview:
      return "Review";
    case DocumentState.submitted:
      return "Ready to Grade";
    case DocumentState.graded:
      return "Graded";
    case DocumentState.grading:
      return "Started Grading";
    case DocumentState.evaluating:
      return "Evaluating";
    case DocumentState.notImported:
      return "Not Submitted";
    case DocumentState.googlePermissionError:
      return "Permission Error";
    default:
      return "Unknown";
  }
});
</script>

<template>
  <div
    class="rounded w-[180px] h-[42px] text-center flex flex-row items-center justify-center"
    :class="{
      'bg-danger-default border-danger-default text-danger-on border-2 ':
        filled &&
        (state == DocumentState.conversionFailed ||
          state == DocumentState.notImported ||
          state == DocumentState.googlePermissionError),
      'text-danger-default  ':
        filled != true &&
        (state == DocumentState.conversionFailed ||
          state == DocumentState.notImported ||
          state == DocumentState.googlePermissionError),
      'bg-medium-default border-medium-default text-medium-on border-2 ':
        filled && state == DocumentState.submitted,
      'text-medium-default border-medium-default  hover:bg-medium-default hover:text-medium-on border-2 ':
        filled != true && state == DocumentState.submitted,
      ' bg-warning-default border-warning-default text-warning-on border-2 ':
        filled &&
        (state == DocumentState.pending ||
          state == DocumentState.uploading ||
          state == DocumentState.converting ||
          state == DocumentState.studentReview),
      ' text-warning-default border border-warning-default  hover:bg-warning-default hover:text-warning-on ':
        filled != true &&
        (state == DocumentState.pending ||
          state == DocumentState.uploading ||
          state == DocumentState.converting ||
          state == DocumentState.studentReview),
      ' bg-primary-default border-primary-default text-primary-on border-2 ':
        filled && state == DocumentState.grading,
      ' text-primary-default  border border-primary-default hover:bg-primary-default hover:text-primary-on':
        filled != true && state == DocumentState.grading,
      ' bg-success-default border-success-default text-success-on border-2 ':
        filled && state == DocumentState.graded,
      ' text-success-default  border border-success-default hover:bg-success-default hover:text-success-on':
        filled != true && state == DocumentState.graded,
      ' bg-purple-900 border-purple-900 text-success-on border-2 ':
        filled && state == DocumentState.evaluating,
      ' text-purple-900 border border-purple-900 hover:bg-purple-900 hover:text-primary-on ':
        filled != true && state == DocumentState.evaluating,
    }"
  >
    <span class="leading-1 mr-1">
      {{ stateLabel }}
    </span>
    <span
      v-if="state == DocumentState.googlePermissionError"
      v-tooltip="
        'We do not have permission to import this file from Google. Please download the file from Google and upload manually.'
      "
      class="mt-[-3px]"
    >
      <icon name="material-symbols:help-outline" class="text-danger-on" />
    </span>
  </div>
</template>

<style scoped></style>
